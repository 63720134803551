import request from 'api/requestUtils'
import { templates } from '@luxuryescapes/lib-uri-templates'
import { Tour, Response } from '@luxuryescapes/contract-svc-tour'

import { tourV2SnapshotMap } from './mappers/TourV2/tourV2SnapshotMapper'

interface GetTourV2OfferIdParams {
  reservationId: string
}

export function getSnapshotDetailsForTourV2Offer({ reservationId }: GetTourV2OfferIdParams) {
  const uri = templates.tour.tour_reservation_snapshot.expand({ reservation_id: reservationId })

  return request.get<App.ApiResponse<Tour.ReservationSnapshot>>(uri, { credentials: 'include' })
    .then(data => tourV2SnapshotMap(data.result))
}

export function sendPrivateRequestForTourV2(requestPrivateTourData: Tours.RequestPrivateTour) {
  return request.post('/api/v2/tours/notify/request-private-tour', requestPrivateTourData)
}

export async function sendWaitingListRequestForTourV2(requestWaitingListTourData: Tours.WaitingListTourRequest) {
  return request.post<App.ApiResponse, Tours.WaitingListTourRequest>('/api/v2/tours/notify/waiting-list-sold-out-tour', requestWaitingListTourData)
}

export function getDepartureInstructions(departureId: string) {
  return request.get<App.ApiResponse<Response.MyEscapesResponse>>(`/api/v2/tours/myEscapes/${departureId}`, { credentials: 'include' }).then(data => data.result)
}

export function getTourOperators(): Promise<Record<string, Tours.TourOperator>> {
  return request.get<App.ApiResponse<Response.GetOperatorsResponse>>('/api/v2/tours/operators')
    .then(data => {
      const operators = {} as Record<string, Tours.TourOperator>
      Object.entries(data.result).forEach(([key, operator]) => {
        operators[key] = {
          name: operator.name,
          imageId: operator.imageId,
          code: operator.code,
        }
      })
      return operators
    })
}
