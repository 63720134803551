import { removeHighIntentOffers } from 'actions/RecommendationActions'
import AbandonedCartTile from 'components/OfferList/HighIntent/AbandonedCartTile'
import { HighIntentTileProps } from 'components/OfferList/HighIntent/HighIntentRecommendationTile'
import HighIntentRecommendationTileLoader from 'components/OfferList/HighIntent/HighIntentRecommendationTileLoader'
import HotLeadTile from 'components/OfferList/HighIntent/HotLeadTile'
import RecentlyViewedTile from 'components/OfferList/HighIntent/RecentlyViewedTile'
import { TrackingProps } from 'contexts/trackingContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import { isAbandonedCart, isHotLead, isRecentlyViewed } from 'lib/offer/highIntentOffers'
import React, { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export enum HighIntentOfferDisplayStatus {
  LOADING,
  SHOW,
  HIDE
}

interface Props {
  highIntentOffer: App.HighIntentOffer;
  position: number;
  tracking?: TrackingProps;
  onClick?: HighIntentTileProps['onClick'];
  onClose?: HighIntentTileProps['onClose'];
  className?: string;
}

function HighIntentOfferCard(props: Props) {
  const { onClose, tracking, highIntentOffer, position, onClick } = props
  const [loadInViewRef, loadInView] = useInView({ triggerOnce: true })
  const [shouldDisplay, setShouldDisplay] = useState(true)

  const recentTile = isRecentlyViewed(highIntentOffer)
  const hotLeadTile = isHotLead(highIntentOffer)
  const abandonedCartTile = isAbandonedCart(highIntentOffer)

  const handleDisplayStatusChange = useCallback((status: HighIntentOfferDisplayStatus) => {
    setShouldDisplay(status === HighIntentOfferDisplayStatus.LOADING || status === HighIntentOfferDisplayStatus.SHOW)
  }, [])

  const cannotRender = !shouldDisplay || (!recentTile && !hotLeadTile && !abandonedCartTile)

  const dispatch = useAppDispatch()
  // if this offer cannot be rendered (unavailable) we want to remove it from the state
  useEffect(() => {
    if (cannotRender) {
      dispatch(removeHighIntentOffers([highIntentOffer.offerId], [highIntentOffer.category]))
    }
  }, [dispatch, highIntentOffer, cannotRender])

  if (cannotRender) {
    return null
  }

  return <div ref={loadInViewRef}>
    {!loadInView && <HighIntentRecommendationTileLoader/>}
    {loadInView && <>
      {recentTile && <RecentlyViewedTile
        position={position}
        offerId={highIntentOffer.offerId}
        tracking={tracking}
        key={highIntentOffer.offerId}
        onClose={onClose}
        onClick={onClick}
        onDisplayStatusChange={handleDisplayStatusChange}
      />}
      {hotLeadTile && <HotLeadTile
        position={position}
        highIntentOffer={highIntentOffer}
        tracking={tracking}
        key={highIntentOffer.offerId}
        onClose={onClose}
        onDisplayStatusChange={handleDisplayStatusChange}
      />}
      {abandonedCartTile && <AbandonedCartTile
        position={position}
        highIntentOffer={highIntentOffer}
        tracking={tracking}
        key={highIntentOffer.offerId}
        onClose={onClose}
        onDisplayStatusChange={handleDisplayStatusChange}
      />}
    </>}
  </div>
}

export default HighIntentOfferCard
